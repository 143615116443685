import React from "react"
import { graphql } from "gatsby"
import ReactHtmlParser from 'react-html-parser';

import SEO from "../../utils/seo"
import Layout from "../../layout/layout"
import ContentContainer from "../../components/ContentContainer"

export default function Template({data}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const { title, subtitle, date, featured_image } = frontmatter
  return (
    <Layout>      
      <SEO title={title} />
      <ContentContainer heading={title} subtitle={subtitle} date={date} featured_image={featured_image}>        
        {ReactHtmlParser(html)}
      </ContentContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        subtitle
        featured_image
      }
    }
  }
`